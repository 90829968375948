import { useRouter } from 'next/router';
import Link from 'next/link';

import {
  DashboardItem,
  DashboardItemLoader,
  DashboardItems,
  ShowView,
  SvgIcon,
} from '@gate-academy/react';
import { formatCurrency } from '@gate-academy/shared/utils';

import type { NextPageWithLayout } from './_app';

import {
  OverviewItem,
  OverviewItems,
  OverviewItemSvgContainer,
  OverviewItemTextContainer,
  // StudentOption,
} from '../styles/homepage.styles';

import Layout from '../layouts/general-layout/general-layout.component';
import UpcomingSession from '~/components/upcoming-session/upcoming-session.component';
import UserTimezoneInfo from '~/components/user-timezone-info/user-timezone-info';

import useStore from '../store';

// import useGetWards from '../react-query/queries/useGetWards';
import useGetOverview from '~/react-query/queries/useGetOverview';
import useGetSessions from '~/react-query/queries/useGetSessions';
import useGetMyWallet from '~/react-query/queries/useGetMyWallet';

import routes from '~/helpers/routes';

const Homepage: NextPageWithLayout = () => {
  const router = useRouter();

  const { isClient, isTutor, currentUser } = useStore((state) => ({
    isClient: state.isClient,
    isTutor: state.isTutor,
    currentUser: state.currentUser,
  }));

  // const { data: wards, isInitialLoading } = useGetWards();

  const { data: overview } = useGetOverview();

  const { data: upcomingSessions, isLoading } = useGetSessions(1, 2, {
    'oneOf[status]': 'upcoming,ongoing',
  });

  const { data: walletData } = useGetMyWallet();

  return (
    <>
      <DashboardItem title="Overview">
        <OverviewItems>
          <OverviewItem
            isClient={isClient}
            onClick={() =>
              router.push(isClient ? routes.wards() : routes.tutees())
            }
          >
            <OverviewItemSvgContainer isClient={isClient}>
              <SvgIcon iconName="person" big />
            </OverviewItemSvgContainer>

            <OverviewItemTextContainer>
              <p>{String(overview?.numWards ?? 0)?.padStart(2, '0')}</p>

              <p>
                {isClient ? 'ward' : 'tutee'}
                {(overview?.numWards ?? 0) > 1 && 's'}
              </p>
            </OverviewItemTextContainer>
          </OverviewItem>

          <ShowView when={isClient}>
            <OverviewItem
              isClient={isClient}
              onClick={() => router.push(routes.tutors())}
            >
              <OverviewItemSvgContainer isClient={isClient}>
                <SvgIcon iconName="add-person" big />
              </OverviewItemSvgContainer>

              <OverviewItemTextContainer>
                <p>
                  {String(overview?.numActiveTutors ?? 0)?.padStart(2, '0')}
                </p>

                <p>
                  tutor
                  {(overview?.numActiveTutors ?? 0) > 1 && 's'}
                </p>
              </OverviewItemTextContainer>
            </OverviewItem>
          </ShowView>

          <OverviewItem
            isClient={isClient}
            onClick={() => router.push(routes.schedule())}
          >
            <OverviewItemSvgContainer isClient={isClient}>
              <SvgIcon iconName="create-with-base" big />
            </OverviewItemSvgContainer>

            <OverviewItemTextContainer>
              <p>
                {String(overview?.numFinishedSessions || 0).padStart(2, '0')}/
                {String(overview?.numTotalSessions || 0).padStart(2, '0')}
              </p>

              <p>
                <span>Classes completed</span>
                <span style={{ display: 'block' }}>this month</span>
              </p>
            </OverviewItemTextContainer>
          </OverviewItem>

          <OverviewItem
            isClient={isClient}
            onClick={() => router.push(routes.wallet())}
          >
            <OverviewItemSvgContainer isClient={isClient}>
              <SvgIcon iconName="monetization" big />
            </OverviewItemSvgContainer>

            <OverviewItemTextContainer>
              <p>
                {formatCurrency(
                  (isTutor
                    ? walletData?.tutorNetBalance
                    : walletData?.clientNetBalance) ?? 0,
                  {
                    currency: isTutor
                      ? currentUser?.earningCurrency
                      : currentUser?.deductibleCurrency,
                  }
                )}
              </p>

              <p>Net Balance</p>
            </OverviewItemTextContainer>
          </OverviewItem>
        </OverviewItems>
      </DashboardItem>

      <DashboardItems>
        <DashboardItem
          title="Classes"
          component={<Link href={routes.schedule()}>View all</Link>}
        >
          <DashboardItemLoader
            isLoading={isLoading}
            hasResult={!!upcomingSessions?.numOfResults}
            loadingText="Getting upcoming sessions..."
            noResultText="You do not have any upcoming sessions"
          >
            {upcomingSessions?.data?.map((el) => (
              <UpcomingSession key={el._id} session={el} />
            ))}

            <UserTimezoneInfo />
          </DashboardItemLoader>
        </DashboardItem>
      </DashboardItems>

      {/* <DashboardItems>
        <DashboardItem title="Progress reports">
          <DashboardItemLoader
            isLoading={isInitialLoading}
            loadingText="Getting wards..."
            hasResult={!!wards?.totalDocuments}
          >
            {wards?.data?.map((ward, i) => (
              <StudentOption key={ward?.id} index={i}>
                <p>
                  {ward?.lastName?.[0]}
                  {ward?.firstName?.[0]}
                </p>

                <p>
                  {ward?.lastName} {ward?.firstName}
                </p>
              </StudentOption>
            ))}
          </DashboardItemLoader>
        </DashboardItem>

        <DashboardItem title="Checklists">
          <DashboardItemLoader
            isLoading={isInitialLoading}
            loadingText="Getting wards..."
            hasResult={!!wards?.totalDocuments}
          >
            {wards?.data?.map((ward, i) => (
              <StudentOption key={ward?.id} index={i}>
                <p>
                  {ward?.lastName?.[0]}
                  {ward?.firstName?.[0]}
                </p>

                <p>
                  {ward?.lastName} {ward?.firstName}
                </p>
              </StudentOption>
            ))}
          </DashboardItemLoader>
        </DashboardItem>
      </DashboardItems> */}
    </>
  );
};

Homepage.getLayout = function (page) {
  const isTutor = useStore.getState().isTutor;

  const pageSubheading = isTutor
    ? 'An overview of all you need to know of your progress with your tutee(s)'
    : 'An overview of all you need to know of your child’s progress';

  return (
    <Layout
      pageTitle="Dashboard"
      pageHeading="Dashboard"
      pageSubheading={pageSubheading}
    >
      {page}
    </Layout>
  );
};

export default Homepage;
