import { DATE_FORMAT, TIME_FORMAT } from '@gate-academy/shared/utils';

export const VALID_RANGE_START = '2023-11-01';

export const FORM_DATE_TIME_FORMAT = DATE_FORMAT + 'T' + TIME_FORMAT;

export const DAY_MONTH_DATE_FORMAT = 'ddd, Do MMM, YYYY';

export const FULL_TIME_FORMAT_12HOUR = 'hh:mm:ss a';

export const DAY_MONTH_DATE_TIME_FORMAT =
  DAY_MONTH_DATE_FORMAT + ' ' + 'hh:mma';

export const TIME_FORMAT_12HOUR = 'hh:mm a';

export const DAY_MONTH_DATE_TIME_SPACE_FORMAT =
  DAY_MONTH_DATE_FORMAT + ' ' + TIME_FORMAT_12HOUR;
