import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { Button, ShowView, SidebarDetail } from '@gate-academy/react';

import { Session, SessionStatusEnum } from '~/types/session.type';

import {
  SessionContainer,
  SessionDuration,
  SessionTime,
  SessionTimes,
  SessionTitle,
} from './upcoming-session.styles';

import EndSession from '../end-session/end-session.component';

import useStore from '~/store';

import useStartSession from '~/react-query/mutations/useStartSession';

import {
  MERIDIAN_TIME_FORMAT,
  capitalizeFirstLetter,
} from '@gate-academy/shared/utils';

import ReactQueryKeys from '~/react-query/keys';

import useTZ from '~/hooks/useTZ';

import { DAY_MONTH_DATE_FORMAT } from '~/utils/constants';

import dayjs from '~/lib/dayjs';

interface IUpcomingSessionProps {
  session: Session;
}

const isSessionStartable = (session: Session, tz: ReturnType<typeof useTZ>) => {
  return tz().isBetween(
    tz(session.startAt).subtract(5, 'minutes'),
    tz(session.startAt).add(session.duration, 'minutes'),
    'minutes',
    '[]'
  );
};

const getSessionDuration = (session: Session, tz: ReturnType<typeof useTZ>) => {
  let duration = session.duration * 60;

  if (session.status === SessionStatusEnum.ONGOING) {
    duration = tz(session.shouldEndAt).diff(tz(), 'seconds');
  }

  return duration;
};

const TIME_FORMAT_24HOUR_WITH_SECONDS = 'HH:mm:ss';

const UpcomingSession = ({ session }: IUpcomingSessionProps) => {
  const tz = useTZ();

  const [canSessionStart, setCanSessionStart] = useState(
    isSessionStartable(session, tz)
  );

  const [duration, setDuration] = useState(getSessionDuration(session, tz));

  const isTutor = useStore((state) => state.isTutor);

  const { mutate: startSession, isLoading } = useStartSession(session._id);

  const isOngoing = session.status === SessionStatusEnum.ONGOING;

  const isSessionToday = tz(session.startAt).isSame(tz(), 'day');

  const queryClient = useQueryClient();

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;

    if (!isTutor) return;

    interval = setInterval(() => {
      setCanSessionStart(isSessionStartable(session, tz));
    }, 1000);

    return () => {
      if (interval && isTutor) {
        clearInterval(interval);
      }
    };
  }, [session, isTutor, tz]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;

    if (session.shouldEndAt) {
      interval = setInterval(() => {
        setDuration(getSessionDuration(session, tz));
      }, 1000);
    }

    if (duration === 0) {
      // these basically just make sure that whenever these hooks enter the
      // foreground, react-query fires off a request to make sure the data is
      // up to date
      queryClient.invalidateQueries([ReactQueryKeys.GET_SESSIONS]);

      queryClient.invalidateQueries([ReactQueryKeys.GET_ENGAGEMENT_SESSIONS]);

      queryClient.invalidateQueries([ReactQueryKeys.GET_OVERVIEW]);

      queryClient.invalidateQueries([ReactQueryKeys.GET_UNPAGINATED_SESSIONS]);

      if (interval) {
        clearInterval(interval);
      }
    }

    return () => {
      if (interval && session.shouldEndAt) {
        clearInterval(interval);
      }
    };
  }, [session, queryClient, duration, tz]);

  return (
    <SessionContainer isOngoing={isOngoing}>
      <SessionTitle>
        <span>
          {capitalizeFirstLetter(session?.tutee?.lastName)}{' '}
          {capitalizeFirstLetter(session?.tutee?.firstName)}
        </span>
        's class{' '}
        {!isTutor && (
          <>
            with{' '}
            <span>
              {capitalizeFirstLetter(session?.tutor?.lastName)}{' '}
              {capitalizeFirstLetter(session?.tutor?.firstName)}
            </span>
          </>
        )}{' '}
        {isOngoing ? (
          <span
            style={{
              textTransform: 'lowercase',
              fontWeight: 400,
              display: 'inline-block',
              width: 'fit-content',
              overflow: 'initial',
              cursor: 'initial',
              padding: '0 5px',
            }}
            className="event-item ongoing-class"
          >
            is ongoing
          </span>
        ) : (
          <>
            will begin {isSessionToday ? ' ' : 'on '}
            <span>
              {tz(session.startAt).format(
                tz(session.startAt).isSame(tz(), 'day')
                  ? '[today]'
                  : DAY_MONTH_DATE_FORMAT
              )}
            </span>
          </>
        )}
      </SessionTitle>

      <SessionTimes>
        <SessionTime>
          <span>
            {tz(isOngoing ? session.startedAt : session.startAt).format(
              MERIDIAN_TIME_FORMAT
            )}
          </span>

          <span>{isOngoing ? 'Started at' : 'Start time'}</span>
        </SessionTime>

        <SessionTime>
          <span>
            {(isOngoing
              ? tz(session.shouldEndAt)
              : tz(session.startAt).add(session.duration, 'minutes')
            ).format(MERIDIAN_TIME_FORMAT)}
          </span>

          <span>{isOngoing ? 'Should end at' : 'End time'}</span>
        </SessionTime>
      </SessionTimes>

      <SessionDuration>
        <span>
          {dayjs
            .duration(duration, 'seconds')
            .format(TIME_FORMAT_24HOUR_WITH_SECONDS)}
        </span>

        <span>Duration</span>
      </SessionDuration>

      <ShowView when={isTutor}>
        <ShowView when={!isOngoing}>
          <Button
            block
            disabled={!canSessionStart}
            isLoading={isLoading}
            loadingText="Starting..."
            onClick={() => {
              startSession();
            }}
          >
            Start class
          </Button>
        </ShowView>

        <ShowView when={isOngoing}>
          <SidebarDetail
            heading="End session?"
            trigger={
              <Button danger block disabled={!duration}>
                End class
              </Button>
            }
          >
            {(close) => (
              <EndSession
                closeSidebar={close}
                sessionId={session._id}
                showQuestion={!tz().isSame(tz(session.shouldEndAt), 'minutes')}
              />
            )}
          </SidebarDetail>
        </ShowView>
      </ShowView>
    </SessionContainer>
  );
};

export default UpcomingSession;
